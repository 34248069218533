<template>
    <h3
        :style="{ textAlign: align }"
        class="public-modal-description"
        :class="{ 'public-modal-description_large': large }"
    >
        <slot></slot>
    </h3>
</template>

<script>
export default {
    props: {
        align: {
            type: String,
            default: "center"
        },
        large: {
            type: Boolean,
            default: false
        }
    },
    name: "PublicModalDescription"
}
</script>

<style scoped lang="sass">
.public-modal-description
    color: #151515
    font-size: 12px
    line-height: 16px
    font-weight: 400
    &_large
        font-size: 13px
        line-height: 17px
        color: #52565C
    strong
        color: #DD4141
    &::v-deep
        em
            color: #3965FF
            font-style: normal
            font-weight: 600
        a
            color: #3965FF
            text-underline-offset: 1px
            cursor: pointer
            &.link
                color: #FF7439
                margin-left: 0
</style>
